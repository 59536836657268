
import { Validation, validationMixin } from 'vuelidate';
import { mask } from 'vue-the-mask';
import { maxValue, minValue, or, requiredIf } from 'vuelidate/lib/validators';
import { dateBR, dateEN } from '@/helpers/validations';
import { TranslateResult } from 'vue-i18n';
import { ValidationGroups } from 'vue/types/vue';
import vuelidateMixin from '@/helpers/vuelidateMixin';
import { mdiCalendar } from '@mdi/js';

interface Data {
  menu: boolean;
  dateDisplay: string;
  datePicker: string;
  menuX: number;
  menuY: number;
}

interface Methods {
  selectedDate(date: string): void;

  emit(): void;

  show(e: MouseEvent): void;
}

interface Computed {
  errors: TranslateResult[];
  maxDate: Date;
  minDate: Date;
  dateObject: Date;
  mdiCalendar: string;
}

interface Props {
  disabled: boolean;
  showCurrent: boolean;
  label: string;
  requiredField: boolean;
  initialValue: string;
  min: string;
  max: string;
}

export default vuelidateMixin.extend<Data, Methods, Computed, Props>({
  name: 'DatePicker',
  mixins: [validationMixin, vuelidateMixin],
  directives: { mask },
  validations() {
    return {
      dateDisplay: {
        date: or(dateBR, dateEN),
        required: requiredIf((vm) => vm.requiredField)
      },
      dateObject: {
        maxValue: maxValue(this.maxDate),
        minValue: minValue(this.minDate)
      }
    };
  },
  data: () => ({
    menu: false,
    dateDisplay: '',
    datePicker: '',
    menuX: 0,
    menuY: 0
  }),
  async created() {
    if (this.initialValue)
      this.dateDisplay = this.dateDisplay = this.$dayjs(
        this.initialValue
      ).format('L');
  },
  methods: {
    selectedDate() {
      this.dateDisplay = this.$dayjs(this.datePicker).format('L');
      this.emit();
      this.$v.dateDisplay.$touch();
      this.menu = false;
    },
    emit() {
      if (this.dateDisplay.length === 10) {
        const date = this.$dayjs(this.dateDisplay, 'L');
        date.hour(0).minute(0).second(0);
        if (!this.$v.dateDisplay.$invalid) {
          this.$emit('date-selected', date.toISOString());
        }
      }
    },
    show(e) {
      e.preventDefault();
      this.menu = false;
      this.menuX = e.clientX;
      this.menuY = e.clientY;
      this.$nextTick(() => {
        this.menu = true;
      });
    }
  },
  computed: {
    mdiCalendar() {
      return mdiCalendar;
    },
    dateObject() {
      return this.$dayjs(this.dateDisplay, 'DD-MM-YYYY').$d;
    },
    maxDate() {
      return this.max
        ? this.$dayjs(this.max).toDate()
        : this.$dayjs().add(100, 'year');
    },
    minDate() {
      return this.min
        ? new Date(this.$dayjs(this.min))
        : this.$dayjs('1920-01-01').toDate();
    },
    errors() {
      const errors: TranslateResult[] = [];
      const field = this.$v.dateDisplay as ValidationGroups & Validation;
      if (!field.$dirty) return [];
      const reqField = this.required(field);
      reqField && errors.push(reqField);
      !field.date && errors.push(this.$t('datePicker.invalidFormat'));
      const fieldDate = this.$v.dateObject as ValidationGroups & Validation;
      !fieldDate.minValue &&
        this.min &&
        errors.push(
          this.$t('app.date-validation.min', {
            date: this.$dayjs(this.min).format('L')
          })
        );
      !fieldDate.maxValue &&
        this.max &&
        errors.push(
          this.$t('app.date-validation.max', {
            date: this.$dayjs(this.max).format('L')
          })
        );
      return errors;
    }
  },
  props: {
    disabled: { type: Boolean, default: false },
    showCurrent: { type: Boolean, default: false },
    label: { type: String, required: true },
    requiredField: { type: Boolean, default: false },
    initialValue: { type: String, default: '' },
    min: { type: String, default: undefined },
    max: { type: String, default: undefined }
  },
  watch: {
    initialValue(val) {
      if (val) this.dateDisplay = this.$dayjs(val).format('L');
    },
    dateDisplay(val) {
      if (val.length != 10) {
        return;
      }
      let dateFromInput = this.$dayjs(val, 'DD-MM-YYYY');

      if (this.min && dateFromInput < this.minDate) {
        return;
      }
      if (this.max && dateFromInput > this.maxDate) {
        return;
      }
      this.datePicker = dateFromInput.toISOString().slice(0, 10);
    }
  }
});
